<template>
  <div>
    <div style="margin-top: 0.5rem; margin-bottom: 1.75rem">
      <p class="ytm-default-text" style="font-size: 1.2rem; font-weight: 600; margin-top: 0.5rem; margin-bottom: 1.5rem">Краткое описание</p>
      <div style="margin-left: 1rem">
        <p class="ytm-default-text ytm-text-small" style="margin-bottom: 1rem">
          Расскажите кратко о своём опыте, образовании и достижениях учеников. Это описание будет показываться
          на поисковой выдаче, а также на карточках с вами
        </p>
        <div v-if="briefEditor" class="form__item editor" style="border: 1px solid grey; border-radius: 1rem; padding: 0.5rem 1rem 1.2rem 1rem">
          <MenuBar :editor="briefEditor" style="display: flex"/>
          <hr style="margin: 0.5rem 0 1rem 0">
          <EditorContent :editor="briefEditor"/>
          <div class="ytm-default-text ytm-text-small ytm-char-count">
            {{ briefEditor.storage.characterCount.characters() }}/{{ briefCharLimit }} символов
          </div>
        </div>
        <p v-if="briefStatus !== 0 && !briefShowButton" :style="getStyleByStatus(briefStatus)">
          {{getTextByStatus(briefStatus, briefComment)}}
        </p>
        <div style="display: flex; justify-content: center">
          <Transition name="ytm-save-when-edited">
            <div
                v-if="briefShowButton"
                class="ytm-blue-button ytm-default-text"
                style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600; margin-top: 0.75rem"
                @click="saveText('brief')"
            >
              Сохранить
            </div>
          </Transition>
        </div>
      </div>
    </div>
    <div style="margin-top: 0.5rem; margin-bottom: 1.75rem">
      <p class="ytm-default-text" style="font-size: 1.2rem; font-weight: 600; margin-top: 0.5rem; margin-bottom: 1.5rem">Полное описание</p>
      <div style="margin-left: 1rem">
        <p class="ytm-default-text ytm-text-small" style="margin-bottom: 1rem">
          Здесь расскажите о себе более подробно – это описание будет показываться на вашей персональной странице
          преподавателя YouTeachMe
        </p>
        <div v-if="aboutEditor" class="form__item editor" style="border: 1px solid grey; border-radius: 1rem; padding: 0.5rem 1rem 1.2rem 1rem">
          <MenuBar :editor="aboutEditor" style="display: flex"/>
          <hr style="margin: 0.5rem 0 1rem 0">
          <EditorContent :editor="aboutEditor"/>
          <div class="ytm-default-text ytm-text-small ytm-char-count">
            {{ aboutEditor.storage.characterCount.characters() }}/{{ aboutCharLimit }} символов
          </div>
        </div>
        <p v-if="aboutStatus !== 0 && !aboutShowButton" :style="getStyleByStatus(aboutStatus)">
          {{getTextByStatus(aboutStatus, aboutComment)}}
        </p>
        <div style="display: flex; justify-content: center">
          <Transition name="ytm-save-when-edited">
            <div
                v-if="aboutShowButton"
                class="ytm-blue-button ytm-default-text"
                style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600; margin-top: 0.75rem"
                @click="saveText('about')"
            >
              Сохранить
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Editor, EditorContent} from "@tiptap/vue-3";
import {Document} from "@tiptap/extension-document";
import {Text} from "@tiptap/extension-text";
import {Paragraph} from "@tiptap/extension-paragraph";
import {Bold} from "@tiptap/extension-bold";
import {Italic} from "@tiptap/extension-italic";
import {Strike} from "@tiptap/extension-strike";
import {Code} from "@tiptap/extension-code";
import {HardBreak} from "@tiptap/extension-hard-break";
import {BulletList} from "@tiptap/extension-bullet-list";
import {OrderedList} from "@tiptap/extension-ordered-list";
import {ListItem} from "@tiptap/extension-list-item";
import {Link} from "@tiptap/extension-link";
import {Placeholder} from "@tiptap/extension-placeholder";
import MenuBar from "./MenuBar";
import CollabEditor from "@/components/MaterialsV2/CollabText/CollabEditor"; // TODO: move css to .css file
import CharacterCount from '@tiptap/extension-character-count';
import axios from "axios";
import {SERVICE_USER_PROFILE_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import {
  VerifiactionStatus_Verified,
  VerificationStatus_Declined,
  VerificationStatus_OnVerification
} from "@/util/constants";

export default {
  name: 'TeacherProfileTab',
  components: {MenuBar, EditorContent, CollabEditor},
  data() {
    return {
      briefContent: '<p></p>',
      briefEditor: null,
      briefShowButton: false,
      briefCharLimit: 280,
      briefStatus: 0,
      briefComment: '',
      aboutContent: '<p></p>',
      aboutEditor: null,
      aboutShowButton: false,
      aboutCharLimit: 2000,
      aboutStatus: 0,
      aboutComment: '',
    };
  },
  methods: {
    getStyleByStatus(status) {
      switch (status) {
        case VerificationStatus_OnVerification:
          return 'margin-left: 1rem; font-size: 0.8rem; color: #343a40';
        case VerifiactionStatus_Verified:
          return 'margin-left: 1rem; font-size: 0.8rem; color: green';
        case VerificationStatus_Declined:
          return 'margin-left: 1rem; font-size: 0.8rem; color: red';
        default:
          return 'margin-left: 1rem; font-size: 0.8rem';
      }
    },
    getTextByStatus(status, adminComment) {
      switch (status) {
        case VerificationStatus_OnVerification:
          return 'Ожидает модерации';
        case VerifiactionStatus_Verified:
          return 'Проверено модератором!';
        case VerificationStatus_Declined:
          return 'Отклонено модератором с комментарием: ' + adminComment;
        default:
          return '';
      }
    },
    isTextEdited() {
      this.briefShowButton = this.briefEditor && this.briefEditor.getHTML() !== this.briefContent;
      this.aboutShowButton = this.aboutEditor && this.aboutEditor.getHTML() !== this.aboutContent;
      setTimeout(this.isTextEdited, 200);
    },
    saveText(which) {
      this.$store.dispatch('auth/autoLogin').then(() => {
        let data = {};
        if (which === 'brief') {
          data['brief'] = this.briefEditor.getHTML();
        } else {
          data['about'] = this.aboutEditor.getHTML();
        }
        axios.put(
            SERVICE_USER_PROFILE_URI + '/teacher/update',
            data,
            {headers: authHeader()},
        ).then(() => {
          axios.get(
              SERVICE_USER_PROFILE_URI + '/teacher/get/private',
              {headers: authHeader()},
          ).then(resp => {
            if (which === 'brief') {
              this.briefContent = resp.data.out.brief.draft ? resp.data.out.brief.draft : (resp.data.out.brief.lastVerified || '<p></p>');
              this.briefStatus = resp.data.out.brief.status;
              this.briefComment = resp.data.out.brief.adminComment;
            } else {
              this.aboutContent = resp.data.out.about.draft ? resp.data.out.about.draft : (resp.data.out.about.lastVerified || '<p></p>');
              this.aboutStatus = resp.data.out.about.status;
              this.aboutComment = resp.data.out.about.adminComment;
            }
          }).catch(err => {
            console.error(err);
          });
        }).catch(err => {
          console.error(err);
        });
      });
    },
  },
  mounted() {
    this.$store.dispatch('auth/autoLogin').then(() => {
      axios.get(
          SERVICE_USER_PROFILE_URI + '/teacher/get/private',
          {headers: authHeader()},
      ).then(resp => {
        this.briefContent = resp.data.out.brief.draft ? resp.data.out.brief.draft : (resp.data.out.brief.lastVerified || '<p></p>');
        this.briefStatus = resp.data.out.brief.status;
        this.briefComment = resp.data.out.brief.adminComment;
        this.aboutContent = resp.data.out.about.draft ? resp.data.out.about.draft : (resp.data.out.about.lastVerified || '<p></p>');
        this.aboutStatus = resp.data.out.about.status;
        this.aboutComment = resp.data.out.about.adminComment;
        this.briefEditor = new Editor({
          content: this.briefContent,
          extensions: [
            Document, Text, Paragraph, Bold, Italic, Strike, Code, HardBreak, BulletList, OrderedList, ListItem, Link,
            Placeholder.configure({
              placeholder: 'Здесь вы можете набирать текст!',
            }),
            CharacterCount.configure({
              limit: this.briefCharLimit,
            }),
          ],
          editable: true,
          autofocus: true,
        });
        this.aboutEditor = new Editor({
          content: this.aboutContent,
          extensions: [
            Document, Text, Paragraph, Bold, Italic, Strike, Code, HardBreak, BulletList, OrderedList, ListItem, Link,
            Placeholder.configure({
              placeholder: 'Здесь вы можете набирать текст!',
            }),
            CharacterCount.configure({
              limit: this.briefCharLimit,
            }),
          ],
          editable: true,
          autofocus: false,
        });
        this.isTextEdited();
      }).catch(err => {
        console.error(err);
      });
    });
  },
  beforeUnmount() {
    if (this.briefEditor) {
      this.briefEditor.destroy();
    }
    if (this.aboutEditor) {
      this.aboutEditor.destroy();
    }
  },
};
</script>

<style scoped>
.ytm-text-small {
  font-size: 0.8rem;
  color: #343a40;
}

.ytm-char-count {
  margin-top: 0.8rem;
  color: #868e96;
}

.ytm-save-when-edited-enter-active,
.ytm-save-when-edited-leave-active {
  transition: opacity 0.2s ease;
}

.ytm-save-when-edited-enter-from,
.ytm-save-when-edited-leave-to {
  opacity: 0;
}
</style>