<template>
    <NewTariffModal v-if="showNewTariffModal" @success="onSuccessPayment" @reset_modal="showNewTariffModal = false"/>
    <div>
        <div v-if="loading" style="display: flex; flex-wrap: wrap; gap: 1rem">
            <div class="skeleton ytm-tariff-card-skeleton"/>
            <div class="skeleton ytm-tariff-card-skeleton"/>
        </div>
        <div v-else-if="curTariff || nextTariff" style="display: flex; flex-wrap: wrap; gap: 1rem">
            <div v-if="curTariff" class="ytm-tariff-card">
                <p class="ytm-default-text" style="font-size: 1.4rem; margin-bottom: 1.2rem; font-weight: 700">{{curTariff.name}}</p>
                <p class="ytm-default-text" style="margin-bottom: 1rem">{{curTariff.desc}}</p>
                <p class="ytm-default-text" style="font-size: 0.8rem; color: #343a40; margin-bottom: 1.2rem">Действует до {{getDateFormat(curTariff.toDT)}}</p>
                <div style="display: flex">
                    <div
                        v-if="!nextTariff"
                        @click="showNewTariffModal = true"
                        class="ytm-blue-button ytm-default-text"
                        style="margin-right: 1rem; color: #FFFFFF; font-weight: 600"
                    >
                        Выбрать следующий
                    </div>
                </div>
            </div>
            <div v-if="nextTariff" class="ytm-tariff-card" style="background-color: #e4fbff">
                <p class="ytm-default-text" style="font-size: 1.4rem; margin-bottom: 1.2rem; font-weight: 700">{{nextTariff.name}}</p>
                <p class="ytm-default-text" style="margin-bottom: 1rem">{{nextTariff.desc}}</p>
                <p class="ytm-default-text" style="font-size: 0.8rem; color: #343a40">Начнётся {{getDateFormat(nextTariff.fromDT)}}</p>
                <p class="ytm-default-text" style="font-size: 0.8rem; color: #343a40; margin-bottom: 1.2rem">и продлится до {{getDateFormat(nextTariff.toDT)}}</p>
            </div>
        </div>
        <div v-else style="display: flex">
            <div class="ytm-tariff-card" style="background-color: #FFFFFF; box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25)">
                <p class="ytm-default-text" style="font-size: 1.4rem; margin-bottom: 1.2rem; font-weight: 700">Нет тарифа</p>
                <p class="ytm-default-text" style="margin-bottom: 1.3rem">Но вы можете выбрать и оплатить тариф прямо сейчас!</p>
                <div style="display: flex">
                    <div
                        @click="showNewTariffModal = true"
                        class="ytm-blue-button ytm-default-text"
                        style="margin-right: 1rem; color: #FFFFFF; font-weight: 600"
                    >
                        Выбрать тариф
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "@/styles/style.css";
import "@/components/CoursePageV2/skeletons.css";
import Container from "@/components/common/Placement/Container";
import axios from "axios";
import {SERVICE_ACCOUNTANT_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import {getStrDate} from "@/util/datetime-to-str";
import NewTariffModal from "@/components/AccountPage/TariffsTab/NewTariffModal.vue";

export default {
    name: 'TariffsTab',
    components: {NewTariffModal, Container},
    data() {
        return {
            loading: true,
            tariffs: [],
            curTariff: null,
            nextTariff: null,
            showNewTariffModal: false,
        };
    },
    methods: {
        getTariffs() {
            this.$store.dispatch('auth/autoLogin').then(() => {
                this.loading = true;
                axios.post(
                    SERVICE_ACCOUNTANT_URI + '/getTariffPeriods',
                    {},
                    {
                        headers: authHeader(),
                    }
                ).then(resp => {
                    this.tariffs = [];
                    for (const entry of resp.data.out) {
                        this.tariffs.push({
                            name: entry.tariff.name,
                            desc: entry.tariff.desc,
                            duration: entry.tariff.duration / (1e9 * 60 * 60 * 24),
                            cost: entry.tariff.cost,
                            fromDT: new Date(Date.parse(entry.fromDT)),
                            toDT: new Date(Date.parse(entry.toDT)),
                        });
                    }
                    const now = new Date();
                    for (const tariff of this.tariffs) {
                        if (tariff.fromDT <= now && now < tariff.toDT) {
                            this.curTariff = tariff;
                        }
                        if (tariff.fromDT > now) {
                            this.nextTariff = tariff;
                        }
                    }
                    this.loading = false;
                }).catch(err => {
                    console.error(err);
                });
            });
        },
        getDateFormat(dt) {
            return getStrDate(dt, true, true);
        },
        onSuccessPayment() {
            this.getTariffs();
        },
    },
    mounted() {
        this.getTariffs();
    },
};
</script>

<style scoped>
.ytm-tariff-card {
    background-color: #CAF4EA;
    border-radius: 1rem;
    padding: 1.5rem 3rem;
}
.ytm-tariff-card-skeleton {
    border-radius: 1rem;
    width: 29rem;
    height: 13rem;
}
</style>