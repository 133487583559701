export const tariffsData = [
    {
        name: 'Начинающий',
        features: ['До 3 активных учеников в месяц', 'Доступ ко всем инструментам для занятий', 'Хранение файлов до 500 МБ'],
        cost: 299,
        subtariffs: [
            {id: 101, duration: 30},
        ],
    },
    {
        name: 'Продвинутый',
        features: ['До 8 активных учеников в месяц', 'Доступ ко всем инструментам для занятий + собственная база вопросов и тестов', 'Хранение файлов до 2 ГБ'],
        cost: 699,
        subtariffs: [
            {id: 201, duration: 30},
            {id: 203, duration: 90, discount: 10},
            {id: 206, duration: 180, discount: 20},
        ],
    },
    {
        name: 'Профессионал',
        features: ['Неограниченный доступ к возможностям YouTeachMe', 'Возможность проводить групповые курсы', 'Хранилище файлов до 15 ГБ'],
        cost: 1299,
        subtariffs: [
            {id: 301, duration: 30},
            {id: 303, duration: 90, discount: 10},
            {id: 306, duration: 180, discount: 20},
        ],
    },
];