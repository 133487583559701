<template>
  <div>
    <p class="ytm-default-text" style="font-size: 0.9rem; font-weight: 500; margin-bottom: 1rem">
      Выберите, письма каких категорий вы хотите получать:
    </p>
    <div class="ytm-letter-checkbox-wrapper">
      <Checkbox v-model="personalizedEnabled" id="personalized" title="Персонализированная рассылка"/>
      <p class="ytm-default-text" style="margin-top: 0.3rem; font-size: 0.8rem; color: #343a40">
        Персональные рекомендации и спецпредложения только для вас
      </p>
    </div>
    <div class="ytm-letter-checkbox-wrapper">
      <Checkbox v-model="massEnabled" id="mass" title="Общая рассылка"/>
      <p class="ytm-default-text" style="margin-top: 0.3rem; font-size: 0.8rem; color: #343a40">
        Обновления платформы, акции и интересные статьи. Никакого спама :)
      </p>
    </div>
    <div style="display: flex">
      <div
          v-if="isChanged"
          class="ytm-blue-button ytm-default-text"
          style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600; margin-top: 0"
          @click="saveSettings"
      >
        Сохранить
      </div>
    </div>
  </div>
</template>

<script>
import "@/styles/style.css";
import Checkbox from "@/components/redesign/Form/Checkbox";

export default {
  name: 'Letters',
  components: {Checkbox},
  data() {
    return {
      personalizedEnabled: true,
      massEnabled: true,
    };
  },
  computed: {
    user_info() {
      return this.$store.getters['user_info/user_info'];
    },
    emailSettings() {
      return this.user_info.notificationsSettings.email;
    },
    isChanged() {
      return this.personalizedEnabled !== this.emailSettings.personalizedEnabled ||
          this.massEnabled !== this.emailSettings.massEnabled;
    },
  },
  methods: {
    saveSettings() {
      let newSettings = this.user_info.notificationsSettings;
      newSettings.email = {
        personalizedEnabled: this.personalizedEnabled,
        massEnabled: this.massEnabled,
      };
      this.$store.dispatch('user_info/editUserInfo', {notificationsSettings: newSettings});
    },
  },
  mounted() {
    this.personalizedEnabled = this.emailSettings.personalizedEnabled;
    this.massEnabled = this.emailSettings.massEnabled;
  },
};
</script>

<style scoped>
.ytm-letter-checkbox-wrapper {
  margin-bottom: 1rem;
}

.ytm-letter-checkbox-wrapper:last-child {
  margin-bottom: 0;
}
</style>