<template>
  <Modal @reset_modal="$emit('reset_modal')">
    <div class="ytm-default-text" style="font-size: 1.75rem; font-weight: 600; text-align: center; margin-bottom: 1.75rem">
      Стать преподавателем
    </div>
    <div v-if="ableToBecomeTeacher" class="ytm-default-text">
      <p class="ytm-default-text" style="margin-bottom: 0.5rem">
        Ваш путь преподавателя <b>YouTeachMe</b> начнётся с&nbsp30-дневного пробного периода.
      </p>
      <p class="ytm-default-text">
        Начните проводить свои лучшие уроки с нами 🚀
      </p>
    </div>
    <p v-else class="ytm-default-text">
      Чтобы стать преподавателем <b>YouTeachMe</b>, надо сделать совсем немного – {{getNecessaryActions()}}
    </p>
    <p v-if="errorMsg" class="ytm-default-text" style="margin-top: 1rem; margin-bottom: -1rem; text-align: center; color: red">
      {{errorMsg}}
    </p>
    <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
      <div
          class="ytm-blue-button ytm-default-text"
          style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
          @click="becomeTeacher"
      >
        {{buttonName}}
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/redesign/Modal";
import "@/styles/style.css";

export default {
  name: 'TeacherModal',
  components: {Modal},
  data() {
    return {
      loading: false,
      buttonName: '',
      errorMsg: null,
    };
  },
  computed: {
    user_info() {
      return this.$store.getters['user_info/user_info'];
    },
    ableToBecomeTeacher() {
      return this.user_info.emailVerified && this.user_info.firstName && this.user_info.surname;
    },
  },
  methods: {
    getNecessaryActions() {
      let actions = [];
      if (!this.user_info.emailVerified) {
        actions.push('подтвердить электронную почту');
      }
      if (!this.user_info.firstName || !this.user_info.surname) {
        actions.push('заполнить имя и фамилию');
      }
      if (actions.length === 0) {
        return '';
      }
      if (actions.length === 1) {
        return actions[0];
      }
      let out = '';
      for (let i = 0; i < actions.length; i++) {
        if (i === 0) {
          out += actions[i];
        } else if (i + 1 === actions.length) {
          out += ' и ' + actions[i];
        } else {
          out += ', ' + actions[i];
        }
      }
      return out;
    },
    becomeTeacher() {
      if (!this.ableToBecomeTeacher) {
        this.$emit('reset_modal');
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$store.dispatch('user_info/becomeTeacher').then(() => {
        this.loading = false;
        this.$emit('reset_modal');
      }).catch(err => {
        this.loading = false;
        this.errorMsg = 'Упс, произошла ошибка, попробуйте ещё раз чуть позже';
      });
    },
  },
  mounted() {
    this.buttonName = this.ableToBecomeTeacher ? 'Начать пробный период' : 'Сейчас сделаю!';
  },
};
</script>

<style scoped>

</style>