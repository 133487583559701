<template>
  <div
      :class="{
                'ytm-lesson-wrapper' : true,
                'ytm-lesson-upcoming' : isUpcoming(lesson),
                'ytm-lesson-in-progress' : isInProgress(lesson)
            }"
  >
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Timetable',
  props: {
    lesson: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isUpcoming(lesson) {
      return new Date(lesson.datetime_start) - new Date() > 0;
    },
    isInProgress(lesson) {
      return new Date() - new Date(lesson.datetime_start) > 0 && new Date(lesson.datetime_finish) - new Date() > 0;
    },
  },
};
</script>

<style scoped>
.ytm-lesson-wrapper {
  width: 100%;
  display: flex;
  margin: 1rem 0;
  padding: 0.2rem 0;
  border: 1px solid lightgrey;
  border-radius: 5px;
  border-left-width: 3px;
}

.ytm-lesson-upcoming {
  border-color: #ffc107;
}

.ytm-lesson-in-progress {
  border-color: limegreen;
}
</style>