<template>
  <div>
    <EmailVerificationModal
        v-if="emailVerificationModalOpened && !user_info.emailVerified"
        @reset_modal="emailVerificationModalOpened = false"
    />
    <p class="ytm-default-text" style="font-size: 1.2rem; font-weight: 600; margin-top: 0.5rem; margin-bottom: 1.5rem">Контактная информация</p>
    <div style="padding-left: 1.5rem; margin-bottom: 2rem">
      <Email @open_email_modal="emailVerificationModalOpened = true"/>
    </div>
    <div v-if="user_info.emailVerified">
      <p class="ytm-default-text" style="font-size: 1.2rem; font-weight: 600; margin-top: 0.5rem; margin-bottom: 1.5rem">Настройки оповещений</p>
      <div style="padding-left: 1.5rem">
        <Letters/>
      </div>
    </div>
  </div>
</template>

<script>
import "@/styles/style.css";
import EmailVerificationModal from "@/components/AccountPage/MainSettingsTab/modals/EmailVerificationModal";
import Email from "@/components/AccountPage/MainSettingsTab/Email";
import Letters from "@/components/AccountPage/MainSettingsTab/Letters";

export default {
  name: 'MainSettingsTab',
  components: {Letters, EmailVerificationModal, Email},
  data() {
    return {
      emailVerificationModalOpened: false,
    };
  },
  computed: {
    user_info() {
      return this.$store.getters['user_info/user_info'];
    },
  },
};
</script>

<style scoped>

</style>