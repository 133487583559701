<template>
  <div>
    <InputGroup
        v-model="email"
        title="Электронная почта"
        placeholder="Формат: ilove@youteachme.ru"
        @keyup.enter="saveEmail"
        :disabled="user_info.emailVerified ? 'true' : null"
        style="margin-bottom: 0.5rem"
    />
    <p v-if="emailError" class="ytm-default-text" style="font-size: 0.8rem; color: red; margin-top: 0.5rem">{{emailError}}</p>
    <p class="ytm-default-text" style="margin-top: 0.5rem; font-size: 0.8rem; color: #343a40">
      С помощью почты вы можете восстановить пароль, а ещё мы присылаем туда только важные новости и приятные сюрпризы!
    </p>
    <div style="display: flex">
      <div
          v-if="email !== user_info.email"
          class="ytm-blue-button ytm-default-text"
          style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600; margin-top: 0.75rem"
          @click="saveEmail"
      >
        Сохранить
      </div>
      <div
          v-else-if="user_info.email.length > 1 && !user_info.emailVerified"
          class="ytm-blue-button ytm-default-text"
          style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600; margin-top: 0.75rem"
          @click="openEmailVerificationModal"
      >
        Подтвердить почту
      </div>
    </div>
  </div>
</template>

<script>
import InputGroup from "@/components/redesign/Form/InputGroup";
import {checkEmail} from "@/util/check-email";

export default {
  name: 'Email',
  components: {InputGroup},
  data() {
    return {
      email: '',
      emailError: '',
    };
  },
  computed: {
    user_info() {
      return this.$store.getters['user_info/user_info'];
    },
  },
  methods: {
    saveEmail() {
      this.emailError = '';
      if (!checkEmail(this.email)) {
        this.emailError = 'Формат: ilove@youteachme.ru';
        return;
      }
      this.$store.dispatch('user_info/editUserInfo', {email: this.email});
    },
    openEmailVerificationModal() {
      this.$emit('open_email_modal');
    },
  },
  mounted() {
    this.email = this.user_info.email;
  },
};
</script>

<style scoped>

</style>