<template>
  <div class="ytm-date-wrapper">
    <div class="ytm-date">
      <p>{{getDDMMYYYY(new Date(lesson.dtStart))}}</p>
      <p>{{getHHMM(new Date(lesson.dtStart))}} – {{getHHMM(new Date(lesson.dtFinish))}}</p>
    </div>
  </div>
</template>

<script>
import {getDDMMYYYY, getHHMM} from "@/util/datetime-to-str";

export default {
  name: 'LessonDate',
  props: {
    lesson: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getDDMMYYYY(date) { return getDDMMYYYY(date); },
    getHHMM(date) { return getHHMM(date); },
  },
};
</script>

<style scoped>
.ytm-date-wrapper {
  display: table;
}

.ytm-date {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  width: 12rem;
  padding: 0.5rem 5px;
  text-align: center;
}
</style>