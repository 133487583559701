<template>
    <Modal v-show="!cpShown" @reset_modal="closeModal">
        <div v-if="currStatus === 'beforePayment'">
            <p class="ytm-default-text" style="font-size: 1.75rem; font-weight: 600; text-align: center; margin-bottom: 2rem">
                Выбрать следующий тариф
            </p>
            <div class="ytm-tariffs">
                <div v-for="tariff in tariffs" :key="tariff.name" class="ytm-tariff-card">
                    <p class="ytm-default-text ytm-tariff-name">
                        {{tariff.name}}
                    </p>
                    <div class="ytm-tariff-features">
                        <p v-for="feature in tariff.features" :key="feature" class="ytm-default-text ytm-tariff-feature">
                            {{feature}}
                        </p>
                    </div>
                    <div class="ytm-default-text ytm-tariff-cost">
                        {{tariff.cost}}&nbsp₽/меcяц
                    </div>
                    <div class="ytm-tariff-subtariffs">
                        <div
                            v-for="subtariff in tariff.subtariffs"
                            :key="subtariff.id"
                            :class="{'ytm-default-text ytm-tariff-subtariff': true, 'ytm-tariff-subtariff-selected': chosen.id === subtariff.id}"
                            @click="choose(subtariff.id)"
                        >
                            {{subtariff.duration / 30}} мес.
                            <div v-if="subtariff.discount" class="ytm-default-text ytm-tariff-discount">
                                -{{subtariff.discount}}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ExpandCollapseTransition>
                <div v-if="chosen.id" class="ytm-chosen-tariff">
                    <p class="ytm-default-text" style="margin-bottom: 0.8rem">
                        Вы выбрали тариф <b>{{chosen.name}}</b> на <b>{{chosen.duration}} дней</b>
                    </p>
                    <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 1.3rem">
                        <p v-if="chosen.cost !== chosen.costWithDiscount" class="ytm-default-text" style="color: #343a40; font-size: 0.9rem; margin-right: 0.5rem">
                            <s>{{chosen.cost}} ₽</s>
                        </p>
                        <p class="ytm-default-text ytm-chosen-tariff-cost">
                            {{chosen.costWithDiscount}} ₽
                        </p>
                    </div>
                    <div style="display: flex; justify-content: center">
                        <div class="ytm-default-text ytm-blue-button" style="color: #FFFFFF; font-weight: 600" @click="startPayment">
                            К оплате
                        </div>
                    </div>
                </div>
            </ExpandCollapseTransition>
        </div>
        <div v-else-if="currStatus === 'successPayment'">
            <p class="ytm-default-text" style="font-size: 1.75rem; font-weight: 600; text-align: center; margin-bottom: 1.75rem">
                Поздравляем 🎉
            </p>
            <p class="ytm-default-text" style="margin-bottom: 1.3rem">
                Вы успешно оплатили тариф <b>{{chosen.name}}</b> на <b>{{chosen.duration}} дней</b>
            </p>
            <div style="display: flex; justify-content: center">
                <div class="ytm-default-text ytm-blue-button" style="color: #FFFFFF; font-weight: 600" @click="closeModal">
                    Вернуться
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import "@/styles/style.css";
import Modal from "@/components/redesign/Modal.vue";
import {computed, ref} from "vue";
import {tariffsData} from "@/components/AccountPage/TariffsTab/tariffs";
import {useStore} from "vuex";
import ExpandCollapseTransition from "@/components/redesign/Transitions/ExpandCollapseTransition.vue";

export default {
    name: 'NewTariffModal',
    components: {ExpandCollapseTransition, Modal},
    setup(props, {emit}) {
        const tariffs = ref(tariffsData);
        const chosen = ref({id: 0});
        const errorMessage = ref('');

        const choose = (id) => {
            errorMessage.value = '';
            for (const tariff of tariffs.value) {
                for (const subtariff of tariff.subtariffs) {
                    if (id === subtariff.id) {
                        const cost = tariff.cost * subtariff.duration / 30;
                        const costWithDiscount = Math.ceil(cost * (1 - (subtariff.discount || 0) / 100));
                        chosen.value = {
                            id: id,
                            name: tariff.name,
                            duration: subtariff.duration,
                            discount: subtariff.discount,
                            cost: cost,
                            costWithDiscount: costWithDiscount,
                        };
                    }
                }
            }
        };

        const store = useStore();
        const user = computed(() => store.getters['user_info/user_info']);
        const cpShown = ref(false);
        const currStatus = ref('beforePayment');

        const startPayment = () => {
            if (!user.value.email || !user.value.username || !chosen.value.id || !chosen.value.costWithDiscount) {
                errorMessage.value = 'Произошла ошибка, уже чиним!';
                return;
            }
            const widgetOptions = {
                language: 'ru-RU',
                email: user.value.email,
                yandexPaySupport: true,
                tinkoffPaySupport: true,
                sbpSupport: true,
            };
            const payments = new cp.CloudPayments(widgetOptions);
            cpShown.value = true;
            payments.pay(
                'charge',
                {
                    publicId: 'pk_70adeb4aef6f3368afe08ff5e2189',
                    description: 'Оплата тарифа YouTeachMe',
                    amount: chosen.value.costWithDiscount,
                    currency: 'RUB',
                    email: user.value.email,
                    skin: 'mini',
                    data: {
                        username: user.value.username,
                        tariffId: chosen.value.id,
                    },
                },
                {
                    onSuccess: () => {
                        currStatus.value = 'successPayment';
                        cpShown.value = false;
                    },
                    onFail: () => {
                        currStatus.value = 'beforePayment';
                        cpShown.value = false;
                    },
                },
            ).then(() => {});
        };

        const closeModal = () => {
            if (currStatus.value === 'successPayment') {
                emit('success');
            }
            emit('reset_modal');
        };

        return {
            tariffs, chosen, errorMessage, choose,
            currStatus, cpShown, startPayment,
            closeModal,
        };
    },
};
</script>

<style scoped>
.ytm-tariffs {
    display: flex;
    overflow: scroll;
    margin-bottom: 1.25rem;
}
.ytm-tariff-card {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 15rem;
    display: flex;
    flex-flow: column;
    margin-right: 1rem;
    padding: 1rem 1.2rem;
    border-radius: 1rem;
    background-color: #e4fbff;
}
.ytm-tariff-name {
    flex-grow: 1;
    text-align: center;
    font-size: 1.15rem;
    font-weight: 600;
    margin-bottom: 1.2rem;
}
.ytm-tariff-features {
    flex-grow: 4;
    display: flex;
    flex-flow: column;
    margin-bottom: 0.5rem;
}
.ytm-tariff-feature {
    text-align: center;
    font-size: 0.9rem;
    color: #343a40;
    margin-bottom: 0.8rem;
}
.ytm-tariff-cost {
    text-align: center;
    font-weight: 600;
    margin-bottom: 1rem;
}
.ytm-tariff-subtariffs {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
}
.ytm-tariff-subtariff {
    position: relative;
    background-color: #ffc107;
    border: 2px solid #e4fbff;
    border-radius: 0.5rem;
    padding: 0.7rem 0.5rem;
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
    white-space: nowrap;
}
.ytm-tariff-subtariff:hover {
    border: 2px solid #A23DF1;
}
.ytm-tariff-subtariff-selected {
    border: 2px solid #A23DF1;
}
.ytm-tariff-discount {
    position: absolute;
    right: -0.5rem;
    top: -0.3rem;
    border-radius: 0.3rem;
    padding: 0.2rem;
    background-color: #A23DF1;
    font-size: 0.6rem;
    font-weight: 700;
    color: #ffffff;
}
.ytm-chosen-tariff {
    text-align: center;
}
.ytm-chosen-tariff-cost {
    border-radius: 0.5rem;
    padding: 0.3rem 0.5rem;
    background-color: #A23DF1;
    font-size: 1.1rem;
    font-weight: 700;
    color: #ffffff;
}
</style>