<template>
  <Modal @reset_modal="$emit('reset_modal')">
    <div class="ytm-default-text" style="font-size: 1.75rem; font-weight: 600; text-align: center; margin-bottom: 1.75rem">
      Настройки
    </div>
    <div>
      <InputGroup class="ytm-modal-input" v-model="formData.firstName" title="Имя" placeholder="Ваше имя" :focus="true" @keyup.enter="updateName"/>
      <InputGroup class="ytm-modal-input" v-model="formData.middleName" title="Отчество" placeholder="Ваше отчество (можно оставить пустым)" @keyup.enter="updateName"/>
      <InputGroup class="ytm-modal-input" v-model="formData.surname" title="Фамилия" placeholder="Ваша фамилия" @keyup.enter="updateName"/>
      <p v-if="errorMsg" class="ytm-default-text" style="margin-top: 1rem; margin-bottom: -1rem; text-align: center; color: red">
        {{errorMsg}}
      </p>
      <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
        <div
            class="ytm-blue-button ytm-default-text"
            style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
            @click="updateName"
        >
          Сохранить
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import "@/styles/style.css";
import Modal from "@/components/redesign/Modal";
import InputGroup from "@/components/redesign/Form/InputGroup";
import SelectGroup from "@/components/redesign/Form/SelectGroup";
import axios from "axios";
import {SERVICE_USER_PROFILE_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";

export default {
  name: 'NameModal',
  components: {Modal, InputGroup, SelectGroup},
  data() {
    return {
      formData: {
        firstName: '',
        middleName: '',
        surname: '',
      },
      errorMsg: '',
      updating: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters['user_info/user_info'];
    },
  },
  methods: {
    validateName(field) {
      const isLetter = c => {
        return c.toLowerCase() !== c.toUpperCase();
      }
      this.errorMsg = '';
      let str = '';
      for (let c of this.formData[field]) {
        if (isLetter(c) || (c === '-' && str.length > 0 && str[str.length - 1] !== '-')) {
          str += c;
        }
      }
      this.formData[field] = str;
      if (this.formData[field].length > 30) {
        this.formData[field] = this.formData[field].substr(0, 30);
      }
    },
    badName(field) {
      this.validateName(field);
      if (field !== 'middleName' && this.formData[field].length === 0) {
        this.errorMsg = (field === 'surname' ? 'Фамилия не может быть пустой' : 'Имя не может быть пустым');
        return true;
      }
      if (this.formData[field].length > 0 && this.formData[field][this.formData[field].length - 1] === '-') {
        this.errorMsg = 'Имя, фамилия или отчество не может заканчиваться на дефис';
        return true;
      }
      return false;
    },
    updateName() {
      if (this.updating) {
        return;
      }
      if (this.badName('firstName') || this.badName('middleName') || this.badName('surname')) {
        return;
      }
      this.updating = true;
      this.$store.dispatch('auth/autoLogin').then(() => {
        axios.put(
            SERVICE_USER_PROFILE_URI + '/users/update',
            this.formData,
            {headers: authHeader()},
        ).then(resp => {
          this.$store.dispatch('user_info/fetchUserInfo').then(() => {
            this.$emit('reset_modal');
            this.updating = false;
          }).catch(() => {
            this.errorMsg = 'Не удалось обновить данные';
            this.updating = false;
          });
        }).catch(err => {
          this.errorMsg = 'Не удалось сохранить данные';
          this.updating = false;
        });
      });
    },
  },
  mounted() {
    if (this.userInfo) {
      this.formData.firstName = this.userInfo.firstName || '';
      this.formData.middleName = this.userInfo.middleName || '';
      this.formData.surname = this.userInfo.surname || '';
    }
  },
};
</script>

<style scoped>
.ytm-modal-input {
  margin-bottom: 1.25rem;
}
</style>