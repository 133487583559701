<template>
  <div>
    <template v-for="(item, index) in items">
      <div class="divider" v-if="item.type === 'divider'" :key="`divider${index}`"/>
      <MenuItem v-else :key="index" v-bind="item"/>
    </template>
  </div>
</template>

<script>
import MenuItem from "@/components/MaterialsV2/CollabText/MenuItem";

export default {
  name: 'MenuBar',
  components: {MenuItem},
  props: {
    editor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [
        {
          icon: 'bold',
          title: 'Жирный текст',
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive('bold'),
        },
        {
          icon: 'italic',
          title: 'Курсив',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive('italic'),
        },
        {
          icon: 'strikethrough',
          title: 'Зачеркнутый текст',
          action: () => this.editor.chain().focus().toggleStrike().run(),
          isActive: () => this.editor.isActive('strike'),
        },
        {
          icon: 'code-view',
          title: 'Код',
          action: () => this.editor.chain().focus().toggleCode().run(),
          isActive: () => this.editor.isActive('code'),
        },
        {
          icon: 'link',
          title: 'Ссылка',
          action: () => this.setLink(),
          isActive: () => this.editor.isActive('link'),
        },
        {
          icon: 'link-unlink',
          title: 'Отменить ссылку',
          action: () => this.editor.chain().focus().unsetLink().run(),
        },
        {
          type: 'divider',
        },
        {
          icon: 'list-unordered',
          title: 'Маркированный список',
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive('bulletList'),
        },
        {
          icon: 'list-ordered',
          title: 'Упорядоченный список',
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => this.editor.isActive('orderedList'),
        },
        {
          type: 'divider',
        },
        {
          icon: 'arrow-go-back-line',
          title: 'Отменить',
          action: () => this.editor.chain().focus().undo().run(),
        },
        {
          icon: 'arrow-go-forward-line',
          title: 'Вернуть отменённое',
          action: () => this.editor.chain().focus().redo().run(),
        },
      ],
    };
  },
  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href;
      const url = window.prompt('URL', previousUrl);
      if (url === null) {
        return;
      }
      if (url === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
      } else {
        this.editor.chain().focus().extendMarkRange('link').setLink({href: url, target: '_blank'}).run();
      }
    },
  },
};
</script>

<style scoped>

</style>