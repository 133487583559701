<template>
  <Modal @reset_modal="$emit('reset_modal')">
    <div class="ytm-default-text" style="font-size: 1.75rem; font-weight: 600; text-align: center; margin-bottom: 1.75rem">
      Подтверждение почты
    </div>
    <div>
      <p class="ytm-default-text" style="margin-bottom: 1rem">
        Сейчас на почту {{user_info.email}} придёт четырёхзначный код, введите его в&nbspполе&nbspниже:
      </p>
      <InputGroup
          class="ytm-modal-input"
          v-model="code"
          title="Код из письма"
          placeholder="1234"
          :focus="true"
          @keyup.enter="confirmCode"
          @update:modelValue="validateCode"
      />
      <div v-if="wasSent" style="margin-top: 0.5rem" @click="sendLetter">
        <span v-if="!sendingLetter" class="ytm-default-text" style="font-size: 0.8rem; color: #000000; cursor: pointer">
          Отправить ещё раз
        </span>
        <span v-else class="ytm-default-text" style="font-size: 0.8rem; color: #000000; cursor: pointer">
          Отправляем письмо...
        </span>
      </div>
      <p v-if="errorMsg" class="ytm-default-text" style="margin-top: 0.5rem; margin-bottom: -1rem; text-align: center; color: red">
        {{errorMsg}}
      </p>
      <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
        <div
            class="ytm-blue-button ytm-default-text"
            style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
            @click="confirmCode"
        >
          Подтвердить
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import "@/styles/style.css";
import Modal from "@/components/redesign/Modal";
import InputGroup from "@/components/redesign/Form/InputGroup";
import axios from "axios";
import {SERVICE_USER_PROFILE_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";

export default {
  name: 'EmailVerificationModal',
  components: {Modal, InputGroup},
  data() {
    return {
      wasSent: false,
      sendingLetter: false,
      nextLetter: null,
      code: '',
      errorMsg: '',
    };
  },
  computed: {
    user_info() {
      return this.$store.getters['user_info/user_info'];
    },
  },
  methods: {
    validateCode() {
      this.errorMsg = '';
      let new_code = '';
      for (let i = 0; i < this.code.length; ++i) {
        if ('0' <= this.code[i] && this.code[i] <= '9') {
          new_code += this.code[i];
        } else {
          this.errorMsg = 'Код должен содержать только цифры';
        }
      }
      this.code = new_code;
      if (this.code.length > 4) {
        this.code = this.code.substr(0, 4);
      }
    },
    getTime() {
      return Math.floor(Date.now() / 1000);
    },
    sendLetter() {
      if (this.sendingLetter) {
        return;
      }
      if (this.nextLetter && this.getTime() < this.nextLetter) {
        const secs = this.nextLetter- this.getTime();
        this.errorMsg = 'Попробуйте через ' + secs + ' секунд';
        return;
      }
      this.sendingLetter = true;
      this.errorMsg = '';
      this.$store.dispatch('auth/autoLogin').then(() => {
        axios.post(
            SERVICE_USER_PROFILE_URI + '/users/email/send',
            {},
            {headers: authHeader()},
        ).then(resp => {
          this.wasSent = true;
          this.nextLetter = this.getTime() + 60;
          this.sendingLetter = false;
        }).catch(err => {
          this.wasSent = true;
          this.errorMsg = 'Не удаётся отправить письмо, попробуйте ещё раз :(';
          this.sendingLetter = false;
        });
      });
    },
    confirmCode() {
      this.validateCode();
      if (this.code.length !== 4) {
        this.errorMsg = 'Код должен состоять из 4 цифр';
      }
      if (this.errorMsg) {
        return;
      }
      this.$store.dispatch('auth/autoLogin').then(() => {
        axios.post(
            SERVICE_USER_PROFILE_URI + '/users/email/verify',
            {code: this.code},
            {headers: authHeader()},
        ).then(resp => {
          if (resp.data.out.confirmed) {
            this.$store.dispatch('user_info/fetchUserInfo');
            this.$emit('reset_modal');
          } else {
            this.errorMsg = 'Неверный код';
          }
        }).catch(err => {
          this.errorMsg = 'Не удалось подтвердить код, попробуйте ещё раз :(';
        });
      });
    },
  },
  mounted() {
    this.sendLetter();
  },
};
</script>

<style scoped>

</style>