<template>
  <div>
    <p class="ytm-text-mm" style="margin-top: 0.5rem; margin-bottom: 1.75rem">Оплаты</p>
    <Container>
      <div style="display: flex; margin-bottom: 1.5rem">
        <Column style="width: 30%; display: table">
          <p style="display: table-cell; vertical-align: middle; height: 100%">
            Выберите период:
          </p>
        </Column>
        <Column>
          <Datepicker
              v-model="dateRange"
              :enableTimePicker="false"
              :format="dateFormat"
              :previewFormat="dateFormat"
              range
              placeholder="Период не выбран"
              @update:modelValue="onDatesChanged"
          />
        </Column>
      </div>
    </Container>
    <p v-if="lessons.length !== 0">
      За этот период вы провели {{lessons.length}} занятий общей продолжительностью
      {{mins >= 60 ? `${Math.floor(mins / 60)} часов ${mins % 60} минут` : `${mins} минут`}}
      и заработали {{money}} рублей.
      <span v-if="n_unpaid === 0">Все занятия отмечены оплаченными!</span>
      <span v-else>А ещё для {{n_unpaid}} занятий отсутствуют отметки об оплате – вы можете либо пометить их оплаченными,
      либо напомнить клиенту об оплате.</span>
    </p>
    <p v-else>
      В этот промежуток времени вы не проводили занятий.
    </p>
    <div style="margin-top: 2.5rem; margin-bottom: 1.5rem; display: flex">
      <div class="ytm-text-l" style="width: 100%">
        Все занятия за этот период:
      </div>
      <div class="text-right" style="display: table; cursor: pointer" @click="changeUnpaidShowingState">
        <span v-if="!show_only_unpaid" style="white-space: pre; display: table-cell; vertical-align: middle">
          Показать только неоплаченные
        </span>
        <span v-else style="white-space: pre; display: table-cell; vertical-align: middle">
          Показать все
        </span>
      </div>
    </div>
    <div v-for="lesson in lessons" :key="lesson.id">
      <Timetable
          v-if="!show_only_unpaid || isUnpaid(lesson)"
          :lesson="lesson"
      >
        <LessonDate :lesson="lesson"/>
        <TimetableLessonInfo
            :course_link="lesson.shortLink"
            :course_title="lesson.course_title"
            :lesson_id="lesson.lessonId"
            :lesson_topic="lesson.topic"
        />
        <div style="display: table; margin-right: 1rem; position: relative">
          <div style="display: table-cell; vertical-align: middle;">
            <p v-if="getPaidMoney(lesson) > 0" class="ytm-text-mm" style="white-space: pre; color: limegreen">+{{getPaidMoney(lesson)}}</p>
            <p v-if="getUnpaidMoney(lesson) > 0" class="ytm-text-mm" style="white-space: pre; color: #ffc107">?{{getUnpaidMoney(lesson)}}</p>
          </div>
        </div>
      </Timetable>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {SERVICE_MAIN_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Container from "../../common/Placement/Container";
import Timetable from "../../common/Timetable/Timetable";
import LessonDate from "../../common/Timetable/LessonDate";
import TimetableLessonInfo from "../../common/Timetable/TimetableLessonInfo";
import Button from "../../common/Button";

export default {
  name: 'StatsTab',
  components: {
    Button,
    Timetable,
    LessonDate,
    TimetableLessonInfo,
    Container,
    Datepicker,
  },
  data() {
    return {
      courses: null,
      dateRange: null,
      lessons: [],
      mins: 0,
      money: 0,
      debt: 0,
      n_unpaid: 0,
      show_only_unpaid: false,
    };
  },
  methods: {
    getMonthName(id) {
      switch (id) {
        case 1: return 'января';
        case 2: return 'февраля';
        case 3: return 'марта';
        case 4: return 'апреля';
        case 5: return 'мая';
        case 6: return 'июня';
        case 7: return 'июля';
        case 8: return 'августа';
        case 9: return 'сентября';
        case 10: return 'октября';
        case 11: return 'ноября';
        case 12: return 'декабря';
        default: return '';
      }
    },
    oneDateFormat(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day} ${this.getMonthName(month)} ${year}`;
    },
    dateFormat(date) {
      if (!date || !date[0] || !date[1]) {
        return '';
      }
      return `с ${this.oneDateFormat(date[0])} по ${this.oneDateFormat(date[1])}`;
    },
    onDatesChanged() {
      this.dateRange[0].setHours(0, 0, 0, 0);
      this.dateRange[1].setHours(23, 59, 59);
      this.mins = 0;
      this.money = 0;
      this.debt = 0;
      this.n_unpaid = 0;
      this.lessons = [];
      for (const [shortLink, course] of Object.entries(this.courses)) {
        for (const [lessonId, lesson] of Object.entries(course.lessons)) {
          if (!lesson.dtStart || !lesson.dtFinish) {
            continue;
          }
          let start = new Date(lesson.dtStart);
          let finish = new Date(lesson.dtFinish);
          if (start >= this.dateRange[0] && finish <= this.dateRange[1]) {
            const delta = Math.round((finish.getTime() - start.getTime()) / (60 * 1000));
            this.mins += delta;
            let lesson_data = {
              shortLink: shortLink,
              course_title: course.title,
              cost: course.costPerHour * delta / 60,
              lessonId: Number.parseInt(lessonId),
              topic: lesson.topic,
              dtStart: lesson.dtStart,
              dtFinish: lesson.dtFinish,
              nPaid: lesson.paid,
              nNotPaid: lesson.notPaid,
            };
            this.money += this.getPaidMoney(lesson_data);
            this.debt += this.getUnpaidMoney(lesson_data);
            if (this.isUnpaid(lesson_data)) {
              ++this.n_unpaid;
            }
            this.lessons.push(lesson_data);
          }
        }
      }
      this.lessons.sort((lhs, rhs) => {
        if (!lhs.dtStart && !rhs.dtStart) {
          return lhs.lessonId < rhs.lessonId ? -1 : lhs.lessonId === rhs.lessonId ? 0 : 1;
        } else if (!lhs.dtStart) {
          return -1;
        } else if (!rhs.dtStart) {
          return 1;
        } else {
          return lhs.dtStart.localeCompare(rhs.dtStart);
        }
      });
    },
    changeUnpaidShowingState() { this.show_only_unpaid = !this.show_only_unpaid; },
    getPaidMoney(lesson) {
      return lesson.nPaid * lesson.cost;
    },
    getUnpaidMoney(lesson) {
      return lesson.nNotPaid * lesson.cost;
    },
    isUnpaid(lesson) {
      return lesson.nNotPaid > 0;
    },
  },
  mounted() {
    this.$store.dispatch('auth/autoLogin').then(() => {
      axios.post(
          SERVICE_MAIN_URI + '/getUserStats',
          {},
          {
            headers: authHeader(),
          }
      ).then(resp => {
        this.courses = resp.data.out;
        let fromDate = new Date();
        fromDate.setDate(1);
        let toDate = new Date();
        this.dateRange = [fromDate, toDate];
        this.onDatesChanged();
      }).catch(err => {
        console.error(err);
      });
    });
  },
};
</script>

<style scoped>

</style>