<template>
  <div class="ytm-container">
    <Navbar/>
    <NameModal v-if="nameModalOpened" @reset_modal="nameModalOpened = false"/>
    <TeacherModal v-if="teacherModalOpened && !user_info.teacherState.isT" @reset_modal="teacherModalOpened = false"/>
    <div v-if="user_info">
      <div style="display: flex; padding-top: 2rem; padding-bottom: 1.25rem">
        <div style="width: 15%; min-width: 120px">
          <div class="ytm-profilepic-wrapper">
            <img
                :src="user_info.avatar ? user_info.avatar : 'https://t4.ftcdn.net/jpg/04/10/43/77/360_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg'"
                alt="Image"
                class="ytm-profilepic-img"
            />
            <div class="ytm-profilepic-menu">
              <input
                  type="file"
                  class="ytm-profilepic-input"
                  accept="image/png,image/x-png,image/jpeg"
                  @change="uploadNewAvatar($event)"
              />
              <span class="ytm-profilepic-menu-icon">
                <i class="fas fa-camera" style="font-size: 1.75rem"/>
              </span>
              <span class="ytm-default-text ytm-profilepic-menu-text">
                Изменить
              </span>
            </div>
          </div>
          <div class="ytm-default-text" style="margin-top: 0.5rem; text-align: center; color: red">{{this.avatarUploadError}}</div>
        </div>
        <div style="width: 80%">
          <div v-if="user_info" style="padding-left: 8%; padding-top: 3%">
            <p class="ytm-default-text" style="font-size: 2rem; font-weight: 600" v-if="user_info.firstName && user_info.surname">
              {{user_info.firstName}} {{user_info.surname}}
            </p>
            <p class="ytm-default-text" style="font-size: 2rem; font-weight: 600; position: relative" v-else>
              Имя не указано
              <svg
                  class="remix"
                  @click="nameModalOpened = true"
                  style="position: absolute; margin-left: 0.5rem; top: 50%; transform: translateY(-50%); width: 2rem; height: 2rem; cursor: pointer"
              >
                <use :xlink:href="`${remixiconUrl}#ri-edit-fill`" />
              </svg>
            </p>
            <p class="ytm-default-text" style="font-size: 1rem; color: grey; margin-top: 0.75rem">@{{user_info.username}}</p>
            <div v-if="!user_info.teacherState.isT" style="display: flex; margin-top: 1rem">
              <div
                  class="ytm-blue-button ytm-default-text"
                  style="color: #FFFFFF; font-size: 1rem; font-weight: 600"
                  @click="teacherModalOpened = true"
              >
                Стать преподавателем
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="user_info && user_info.teacherState && user_info.teacherState.isT" style="display: flex; margin-top: 0.25rem; margin-bottom: 2rem">
      <div class="ytm-you-are-a-teacher">
        <p class="ytm-default-text" style="font-size: 1.2rem; font-weight: 600; margin-bottom: 1.25rem">
          Вы преподаватель YouTeachMe!
        </p>
        <div style="display: flex">
          <a
              class="ytm-blue-button ytm-default-text"
              href="/tutorial?from=account"
              target="_blank" rel="noopener noreferrer"
              style="color: #FFFFFF; font-weight: 600"
          >
            А как использовать платформу?
          </a>
        </div>
      </div>
    </div>
    <div v-if="user_info && !user_info_loading" style="margin-top: 1rem; margin-bottom: 7rem">
      <div class="ytm-tabs-titles" style="margin-bottom: 1.5rem">
        <div
            :class="{'ytm-tab ytm-default-text ytm-tab-title': true, 'ytm-tab-selected': selectedTab === 'settings'}"
            @click="selectTab('settings')"
        >
          Настройки
        </div>
        <div
            v-if="user_info.teacherState && user_info.teacherState.isT"
            :class="{'ytm-tab ytm-default-text ytm-tab-title': true, 'ytm-tab-selected': selectedTab === 'profile'}"
            @click="selectTab('profile')"
        >
          Профиль преподавателя
        </div>
        <div
            v-if="user_info.teacherState && user_info.teacherState.isT"
            :class="{'ytm-tab ytm-default-text ytm-tab-title': true, 'ytm-tab-selected': selectedTab === 'tariff'}"
            @click="selectTab('tariff')"
        >
          Ваш тариф
        </div>
        <div
            v-if="user_info.teacherState && user_info.teacherState.isT"
            :class="{'ytm-tab ytm-default-text ytm-tab-title': true, 'ytm-tab-selected': selectedTab === 'stats'}"
            @click="selectTab('stats')"
        >
          Статистика оплат
        </div>
      </div>
      <MainSettingsTab v-if="selectedTab === 'settings'"/>
      <TeacherProfileTab v-if="selectedTab === 'profile'"/>
      <TariffsTab v-if="selectedTab === 'tariff'"/>
      <StatsTab v-if="selectedTab === 'stats'"/>
    </div>
    <div v-else style="margin-top: 1rem">
      <p class="ytm-default-text ytm-onboarding-text">Загрузка...</p>
    </div>
  </div>
</template>

<script>
import "@/styles/style.css";
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg';
import MainSettingsTab from "./MainSettingsTab/MainSettingsTab";
import StatsTab from "./StatsTab/StatsTab";
import NameModal from "@/components/AccountPage/MainSettingsTab/modals/NameModal";
import Container from "@/components/common/Placement/Container";
import TeacherProfileTab from "@/components/AccountPage/TeacherProfileTab/TeacherProfileTab";
import {provide, ref} from "vue";
import {useRoute} from "vue-router";
import TariffsTab from "@/components/AccountPage/TariffsTab/TariffsTab";
import Navbar from "@/components/redesign/Navbar";
import TeacherModal from "@/components/AccountPage/MainSettingsTab/modals/TeacherModal";

export default {
  name: 'AccountPage',
  components: {TeacherModal, Navbar, TariffsTab, TeacherProfileTab, Container, NameModal, StatsTab, MainSettingsTab},
  computed: {
    user_info() {
      return this.$store.getters['user_info/user_info'];
    },
    user_info_loading() {
      return this.$store.getters['user_info/loading'];
    },
  },
  data() {
    return {
      remixiconUrl,
      avatarUploadError: '',
      nameModalOpened: false,
      teacherModalOpened: false,
    };
  },
  setup() {
    const selectedTab = ref('');
    const route = useRoute();
    if (route.query.section) {
      selectedTab.value = route.query.section;
    } else {
      selectedTab.value = 'settings';
    }
    provide('selectedTab', selectedTab);
    return {selectedTab};
  },
  methods: {
    uploadNewAvatar(event) {
      this.avatarUploadError = '';
      if (event.target.files.length !== 1) {
        return;
      }
      if (event.target.files[0].size > 200 * 1024) {
        this.avatarUploadError = 'Файл должен быть не более 200 КБайт';
      }
      let payload = new FormData();
      payload.append('avatar', event.target.files[0]);
      this.$store.dispatch('user_info/updateAvatar', payload);
    },
    selectTab(tabName) {
      this.selectedTab = tabName;
      let query = {};
      if (tabName !== '') {
        query.section = tabName;
      }
      this.$router.replace({query: query});
    },
  },
  mounted() {
    this.$store.dispatch('user_info/fetchUserInfo');
  },
};
</script>

<style scoped>
.ytm-profilepic-wrapper {
  position: relative;
  width: 100%;
  padding-top: 100%;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
}

.ytm-profilepic-input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.ytm-profilepic-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-filter: brightness(100%);
  transition: all .2s ease-in-out;
}

.ytm-profilepic-menu {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0;
  cursor: pointer;
  transition: opacity .2s ease-in-out;
}

.ytm-profilepic-menu-icon {
  color: white;
  padding-bottom: 0.5rem;
}

.ytm-profilepic-menu-text {
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
}

.ytm-profilepic-wrapper:hover .ytm-profilepic-img {
  cursor: pointer;
  -webkit-filter: brightness(50%);
}

.ytm-profilepic-wrapper:hover .ytm-profilepic-menu {
  opacity: 1;
}

.ytm-you-are-a-teacher {
  background: #FFFFFF;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
  padding: 2.25rem 4.5rem 1.75rem 4.5rem;
}
</style>