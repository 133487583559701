<template>
  <div class="ytm-lesson-info">
    <p style="margin-bottom: 0.6rem">
      <a
          class="ytm-course-title"
          :href="'https://youteachme.ru/course/' + course_link"
      >
        {{course_title}}
      </a>
    </p>
    <p>
      <span
          @click="$router.push('/course/' + course_link + '?lesson=' + lesson_id)"
          style="cursor: pointer"
      >
        <i class="fa-solid fa-users-rectangle" style="margin-right: 0.5rem"/>
        <span style="font-style: italic">{{lesson_topic}}</span>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'TimetableLessonInfo',
  props: {
    course_link: {
      type: String,
      required: true,
    },
    course_title: {
      type: String,
      required: true,
    },
    lesson_id: {
      type: Number,
      required: true,
    },
    lesson_topic: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.ytm-lesson-info {
  width: 100%;
  padding: 0.5rem 1rem 0.75rem 1rem;
}

.ytm-course-title {
  color: black;
  font-weight: 500;
  font-size: 1.2rem;
}
</style>